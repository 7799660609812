import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorService {
  rateLimitError = 'An error occurred due to too many requests. Please wait a minute and try again.';

  constructor() {}

  /**
   * Attempt to get provider information based on the format of the message.
   * @param er HttpErrorResponse object to be examined.
   */
  getProvider(er: HttpErrorResponse) {
    let provider;
    if (er && er.error && er.error.error) {
      provider = 'novadine';
    } else if (er && er.error && er.error.num && er.error.message) {
      provider = 'olo';
    } else if (er && er.error && er.error.conflictingFields) {
      provider = 'paytronix';
    } else {
      provider = 'other';
    }
    return provider;
  }

  loginError(er: HttpErrorResponse): string {
    let erString = 'Error logging in. Please check your username and password and try again. If the issue persists, please contact us.';

    // Handle rate limiting
    if (er.status === 429 || er.status === 0) {
      erString = this.rateLimitError;
    }

    const provider = this.getProvider(er);

    if (provider === 'olo' && er.status === 400) {
      erString = er.error.message;
    }

    if (er.status === 401) {
      // 401 means authentication error.
      erString = 'The username and/or password entered is incorrect.';
    } else if (er.status === 404) {
      // Not found, may be offline. Any vendor.
      erString = 'Cannot reach login server. Please try again momentarily.';
    }

    if (er.name.includes('Email Verification')) {
      erString = er.message;
    }

    return erString;
  }

  registerError(er: HttpErrorResponse): string {
    let erString =
      'Error creating an account. There may already be an account with this information. In that case, try logging in. If the issue persists, please contact us.';

    // Handle rate limiting
    if (er.status === 429 || er.status === 0) {
      erString = this.rateLimitError;
    }

    const provider = this.getProvider(er);

    if (provider === 'olo' && er.error?.message) {
      erString = er.error.message;
    }

    if (provider === 'paytronix') {
      if (er.error.result === 'uniquenessConflict') {
        erString =
          'Error creating an account, there is already an account with this information. The following are conflicting with an existing account: ';
        er.error.conflictingFields.forEach((field: string, index: number) => {
          console.log(field);
          if (field === 'mobilePhone') {
            erString = erString.concat('phone number');
          }
          if (field === 'email') {
            erString = erString.concat('email address');
          }
          if (er.error.conflictingFields.length - 1 > index) {
            erString = erString.concat(', ');
          }
        });
      }
    }

    if (er.status === 403 && er.error.code === 6) {
      // Olo-specific. The API key does not support registration. Which is bad.
      erString = 'Sorry, we are not able to accept new registrations at this time. Please order as a guest.';
    } else if (er.status === 404) {
      // Not found, may be offline. Any vendor.
      erString = 'Cannot reach login server. Please try again momentarily.';
    } else if (er.status === 422 && provider === 'other') {
      // Punchh uses this code for username/password already taken.
      erString = 'An account with this info already exists. Either sign in instead, or else use different info.';
    }

    if (er.message?.includes('Please select a location to see your rewards')) {
      erString = '';
    }

    if (er.name.includes('Email Verification')) {
      erString = er.message;
    }

    return erString;
  }

  checkoutError(er: HttpErrorResponse): string {
    let erString =
      'There was an error submitting your order. Please check your details and try again. If the issue persists, please contact us.';

    // Handle rate limiting
    if (er.status === 429 || er.status === 0) {
      erString = this.rateLimitError;
    }

    const provider = this.getProvider(er);

    if (provider === 'olo' && er.error?.message) {
      erString = er.error.message;
      if (er.error.message.toLowerCase().includes('no billing account specified')) {
        erString = 'Your payment method is either incomplete or missing. Please add a payment method and try again.';
      }
    }

    if (er.status === 404) {
      // Not found, may be offline. Any vendor.
      erString = 'Cannot reach ordering server. Please try again momentarily.';
    }

    if (
      er.message &&
      (er.message === 'A duplicate transaction has been submitted.' || er.message === 'This transaction has been declined.')
    ) {
      erString =
        'There was an error submitting your order, your card may have been declined or the information submitted could be incorrect. Please check your details and try again. If the issue persists, please contact the store.';
    }

    return erString;
  }

  rewardApplyError(er: HttpErrorResponse): string {
    let erString = 'There was an error applying your reward. Please wait a minute and try again. If the issue persists please contact us.';

    // Handle rate limiting
    if (er.status === 429 || er.status === 0) {
      erString = this.rateLimitError;
    }

    const provider = this.getProvider(er);

    if (provider === 'olo') {
      if (er.error.message) {
        erString = er.error.message;
      }
      if (er.error.num === 218) {
        erString = 'The store is currently is currently closed, please select a time for your order before continuing.';
      }
    }

    if (er.status === 404) {
      // Not found, may be offline. Any vendor.
      erString = 'Cannot reach ordering server. Please try again momentarily.';
    }

    return erString;
  }

  productError(er: HttpErrorResponse): string {
    let erString =
      typeof er === 'string' ? er : 'There was an error loading this product. Please try again. If the issue persists please contact us.';

    // Handle rate limiting
    if (er.status === 429 || er.status === 0) {
      erString = this.rateLimitError;
    }

    const provider = this.getProvider(er);

    if (provider === 'olo' && er.error?.message) {
      erString = er.error.message;
    }

    if (er.status === 404) {
      // Not found, may be offline. Any vendor.
      erString = 'Cannot reach ordering server. Please try again momentarily.';
    }

    return erString;
  }

  locationRewardsError(er: HttpErrorResponse): string {
    let erString = 'Please select a location to see your rewards';

    // Handle rate limiting specifically
    if (er.status === 429) {
      erString = 'An error occurred due to too many requests. Please wait a minute and try again.';
    }

    // Handle network connectivity issues
    else if (er.status === 0) {
      erString = 'Cannot reach rewards server. Please check your connection and try again.';
    }

    console.log(er.message);

    const provider = this.getProvider(er);

    if (provider === 'olo' && er.error?.message) {
      erString = er.error.message;
    }

    if (er.status === 404) {
      // Not found, may be offline. Any vendor.
      erString = 'Cannot reach ordering server. Please try again momentarily.';
    }

    if (er.message === 'Please select a location to see your rewards.') {
      // Not found, may be offline. Any vendor.
      erString = 'Please select a location to see your rewards.';
    }

    return erString;
  }
}
