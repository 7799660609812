import { Component, EventEmitter, Input, OnInit, AfterViewInit, OnDestroy, Output, ElementRef, ViewChild } from '@angular/core';
import { Category } from '../../../../interfaces/category.interface';
import { Router } from '@angular/router';
import { SlugPipe } from '@common/services/slug.pipe';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { User } from '../../../../interfaces/user.interface';
import { UserRoles } from '@common/components';
import { ModeService } from '../../../../services/mode.service';
import { NavbarSettings } from '../../../../vendors/directus/interfaces/navbar-settings.interface';

@Component({
  selector: 'app-category-jump-nav',
  templateUrl: './category-jump-nav.component.html',
  styleUrls: ['category-jump-nav.component.scss'],
})
export class CategoryJumpNavComponent implements OnInit, AfterViewInit, OnDestroy {
  @Select(state => state.menu.showFullMenu) showFullMenu$: Observable<boolean>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;
  @Input() categories: Category[];
  @Output() clickCategory = new EventEmitter<Category>();

  activeCategory: Category;
  roles = UserRoles;
  private observer: IntersectionObserver;
  isManualScroll = false; // flag to disable observer updates during manual scroll

  // Variables for drag-to-scroll
  private isDown = false;
  private startX: number;
  private scrollLeft: number;

  @ViewChild('jumpLinksContainer', { static: false }) jumpLinksContainer: ElementRef;

  constructor(
    private router: Router,
    private slugPipe: SlugPipe,
    public modeService: ModeService
  ) {}

  ngOnInit() {
    // Initialize active category with the first category.
    this.activeCategory = this.categories[0];
  }

  ngAfterViewInit() {
    this.enableDragToScroll();
    const options = {
      root: null, // relative to viewport
      threshold: 0.1, // lower threshold to catch partial visibility
    };

    this.observer = new IntersectionObserver(entries => {
      // Skip updating if a manual scroll is in progress.
      if (this.isManualScroll) {
        return;
      }

      // Filter entries that are visible.
      const visibleEntries = entries.filter(entry => entry.isIntersecting);
      if (visibleEntries.length) {
        let candidate;
        // Look for entries whose top is >= 0.
        const positiveTopEntries = visibleEntries.filter(entry => entry.boundingClientRect.top >= 0);
        if (positiveTopEntries.length) {
          positiveTopEntries.sort((a, b) => a.boundingClientRect.top - b.boundingClientRect.top);
          candidate = positiveTopEntries[0];
        } else {
          // Otherwise, choose the one with the largest top value.
          visibleEntries.sort((a, b) => b.boundingClientRect.top - a.boundingClientRect.top);
          candidate = visibleEntries[0];
        }
        const elementId = candidate.target.getAttribute('id');
        const active = this.categories.find(category => this.slugPipe.transform(category.name) === elementId);
        if (active && active !== this.activeCategory) {
          this.activeCategory = active;
          this.scrollActiveCategoryIntoView();
        }
      }
    }, options);

    // Attach observer after a delay to ensure sections are rendered.
    setTimeout(() => {
      this.categories.forEach(category => {
        const elementId = this.slugPipe.transform(category.name);
        const element = document.getElementById(elementId);
        if (element) {
          this.observer.observe(element);
        }
      });
    }, 500);
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  scroll(id: string, index: number) {
    // Set flag to disable observer updates.
    this.isManualScroll = true;

    this.activeCategory = this.categories[index];

    // Scroll the main content section into view.
    const contentEl = document.getElementById(this.slugPipe.transform(id));
    if (contentEl) {
      contentEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const category = this.categories.find(cat => cat.name === id);
    this.clickedCategory(category);

    // After a delay, scroll the nav container so the active category is left-aligned
    // and re-enable observer updates.
    setTimeout(() => {
      this.scrollActiveCategoryIntoView();
      this.isManualScroll = false;
    }, 800); // Increase delay as needed to let the main scroll finish.
  }

  clickedCategory(category: Category) {
    this.clickCategory.emit(category);
  }

  // This method scrolls the jump nav container so that the active nav link is at the left.
  private scrollActiveCategoryIntoView() {
    const activeNav = document.getElementById('nav-' + this.slugPipe.transform(this.activeCategory.name));
    if (activeNav && this.jumpLinksContainer) {
      activeNav.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    }
  }

  categoryTrackBy(index: number, category: Category): string {
    return `${index}${category.name}${category.categoryID}`;
  }

  // Drag-to-scroll: attach mouse events
  private enableDragToScroll() {
    const container = this.jumpLinksContainer.nativeElement;
    // Mouse events
    container.addEventListener('mousedown', this.onMouseDown);
    container.addEventListener('mouseleave', this.onMouseLeave);
    container.addEventListener('mouseup', this.onMouseUp);
    container.addEventListener('mousemove', this.onMouseMove);

    // Optionally, you can also add touch events for mobile devices:
    container.addEventListener('touchstart', this.onTouchStart, { passive: true });
    container.addEventListener('touchmove', this.onTouchMove, { passive: false });
    container.addEventListener('touchend', this.onTouchEnd, { passive: true });
  }

  private disableDragToScroll() {
    const container = this.jumpLinksContainer.nativeElement;
    container.removeEventListener('mousedown', this.onMouseDown);
    container.removeEventListener('mouseleave', this.onMouseLeave);
    container.removeEventListener('mouseup', this.onMouseUp);
    container.removeEventListener('mousemove', this.onMouseMove);

    container.removeEventListener('touchstart', this.onTouchStart);
    container.removeEventListener('touchmove', this.onTouchMove);
    container.removeEventListener('touchend', this.onTouchEnd);
  }

  // Mouse Event Handlers
  private onMouseDown = (e: MouseEvent) => {
    this.isDown = true;
    const container = this.jumpLinksContainer.nativeElement;
    container.classList.add('active'); // Optional: for styling during drag
    this.startX = e.pageX - container.offsetLeft;
    this.scrollLeft = container.scrollLeft;
  };

  private onMouseLeave = () => {
    this.isDown = false;
    this.jumpLinksContainer.nativeElement.classList.remove('active');
  };

  private onMouseUp = () => {
    this.isDown = false;
    this.jumpLinksContainer.nativeElement.classList.remove('active');
  };

  private onMouseMove = (e: MouseEvent) => {
    if (!this.isDown) return;
    e.preventDefault();
    const container = this.jumpLinksContainer.nativeElement;
    const x = e.pageX - container.offsetLeft;
    const walk = (x - this.startX) * 1; // Adjust multiplier for sensitivity
    container.scrollLeft = this.scrollLeft - walk;
  };

  // Touch Event Handlers (for mobile)
  private onTouchStart = (e: TouchEvent) => {
    const container = this.jumpLinksContainer.nativeElement;
    this.isDown = true;
    this.startX = e.touches[0].pageX - container.offsetLeft;
    this.scrollLeft = container.scrollLeft;
  };

  private onTouchMove = (e: TouchEvent) => {
    if (!this.isDown) return;
    e.preventDefault();
    const container = this.jumpLinksContainer.nativeElement;
    const x = e.touches[0].pageX - container.offsetLeft;
    const walk = (x - this.startX) * 1; // Adjust multiplier for sensitivity
    container.scrollLeft = this.scrollLeft - walk;
  };

  private onTouchEnd = () => {
    this.isDown = false;
  };
}
