<ng-container *ngIf="(menu$ | async) as menu">
<!--    <ng-container *ngIf="modeService.mode !== 'desktop'">-->
    <app-category-jump-nav
        [categories]="menu.categories"
    ></app-category-jump-nav>
<!--    </ng-container>-->
</ng-container>

<app-order-controls></app-order-controls>

<ng-container *ngIf="menuPromotionalImageMobile">
    <div class="d-flex d-md-none w-100 pt-3 px-3">
        <app-promotional-content promoSection="menu"></app-promotional-content>
    </div>
</ng-container>
<ng-container *ngIf="menuPromotionalImage">
    <div class="d-none d-md-flex w-100 pt-3 px-3">
        <app-promotional-content promoSection="menu"></app-promotional-content>
    </div>
</ng-container>

<div class="category-list">
    <ng-container *ngIf="(mainSettings$ | async) as mainSettings">
        <ng-container *ngIf="(order$ | async) as order">
            <ng-container *ngIf="(menu$ | async) as menu">
                <ng-container *ngIf="(user$ | async) as user">
                    <ng-container
                            *ngFor="let category of (menu.categories | secretCategories: (user.isGuest ? roles.guest : roles.all) | daypartCategories: (showFullMenu$ | async)); let i = index; trackBy: categoryTrackBy">
                        <ng-container *ngIf="!category.isHidden && category.products && category.products.length">
                            <app-category-scroller
                                [category]="category"
                                [modifyModalRef]="modifyModalRef"
                                [productLoading]="menuService.productLoading"
                                [showFullMenu]="(!order?.location?.supportsGroupOrders || !mainSettings.show_group_order) && i === 0"
                                (clickCategory)="menuService.setCategory(category)"
                            ></app-category-scroller>
                        </ng-container>
                    </ng-container>
              </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

<ng-template #modifyModal let-modal>
    <app-modify-modal [isLoading]="menuService.settingProduct" (closeModal)="modal.close()"></app-modify-modal>
</ng-template>
