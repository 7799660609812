import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Menu } from '../../../../interfaces/menu.interface';
import { MenuService } from '@modules/menu/services';
import { Category } from '../../../../interfaces/category.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Order } from '../../../../interfaces/order.interface';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';
import { NavigationService } from '@modules/navigation/services';
import { User } from '../../../../interfaces/user.interface';
import { SubscriptionComponent, UserRoles } from '@common/components';
import { ModeService } from '../../../../services/mode.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { PromotionalContent } from '../../../../vendors/directus/interfaces/promotional-content.interface';

@Component({
  selector: 'app-menu-block',
  templateUrl: './menu-block.component.html',
  styleUrls: ['menu-block.component.scss'],
})
export class MenuBlockComponent extends SubscriptionComponent implements OnDestroy, OnInit {
  page;
  cartPromotionalText: SafeHtml;
  cartPromotionalImage;
  cartPromotionalImageMobile;
  catPromotionalImage;
  catPromotionalImageMobile;
  catPromotionalText: SafeHtml;
  menuPromotionalImage;
  menuPromotionalImageMobile;
  menuPromotionalText: SafeHtml;
  roles = UserRoles;
  @Select(state => state.menu.menu) menu$: Observable<Menu>;
  @Select(state => state.menu.showFullMenu) showFullMenu$: Observable<boolean>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.app.promotionalContent) promotionalContent$: Observable<PromotionalContent>;
  @ViewChild('modifyModal') modifyModalRef: TemplateRef<any>;

  @Input() promoSection: 'cart' | 'upsell' | 'confirm' | 'category' | 'menu';

  constructor(
    private store: Store,
    public menuService: MenuService,
    public modalService: NgbModal,
    private navigation: NavigationService,
    public modeService: ModeService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit() {
    this.subs.push(
      this.promotionalContent$.pipe(filter(pc => !!pc)).subscribe(pages => {
        this.page = pages[0];
        if (!this.page || (!this.page?.category_card_background && this.page?.category_card_background_mobile)) {
          this.catPromotionalText = this.sanitizer.bypassSecurityTrustHtml('');
        } else {
          this.catPromotionalImage = this.page?.category_card_background?.data.full_url;
          this.catPromotionalImageMobile = this.page?.category_card_background_mobile?.data.full_url;
          this.catPromotionalText = this.sanitizer.bypassSecurityTrustHtml(this.page?.category_card_promotion);
        }
        if (!this.page || (!this.page?.cart_promotion_background && !this.page?.cart_promotion_background_mobile)) {
          this.cartPromotionalText = this.sanitizer.bypassSecurityTrustHtml('');
        } else {
          this.cartPromotionalText = this.sanitizer.bypassSecurityTrustHtml(this.page.cart_promotion_text);
          this.cartPromotionalImage = this.page?.cart_promotion_background?.data.full_url;
          this.cartPromotionalImageMobile = this.page?.cart_promotion_background_mobile?.data.full_url;
        }
        if (!this.page || (!this.page?.menu_promotion_background && !this.page?.menu_promotion_background_mobile)) {
          this.menuPromotionalText = this.sanitizer.bypassSecurityTrustHtml('');
        } else {
          this.menuPromotionalText = this.sanitizer.bypassSecurityTrustHtml(this.page?.menu_promotion_text);
          this.menuPromotionalImage = this.page?.menu_promotion_background?.data.full_url;
          this.menuPromotionalImageMobile = this.page?.menu_promotion_background_mobile?.data.full_url;
        }
      })
    );
  }

  categoryTrackBy(index: number, category: Category): string {
    return `${index}${category.name}${category.categoryID}`;
  }
  startGroupOrder() {
    this.navigation.navigateToStartGroupOrderPage();
  }
}
