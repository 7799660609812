import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GlobalStateModel } from '../../../../store/state.model';
import { PwaSettings } from '../../../../vendors/directus/interfaces/pwa-settings.interface';
import { firstValueFrom, Observable, timer } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { SplashScreen } from '@capacitor/splash-screen';
import { environment } from '../../../../environments/environment';
import { Capacitor } from '@capacitor/core';
import { BundleInfo, CapacitorUpdater, latestVersion } from '@appmassive/capacitor-updater';
import { ModalController } from '@ionic/angular';
import { filter, take } from 'rxjs/operators';
import { Preferences as Storage } from '@capacitor/preferences';
import { OnboardingSlidesComponent } from '@common/components';
import { Onboarding } from '../../../../vendors/directus/interfaces/onboarding.interface';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { InitializeUser, LogOut, SetAdditionalUserFields, SignIn } from '../../../../store/actions/user.actions';
import { TokenManagerService } from '@common/services';

enum DownloadState {
  CHECKING,
  FOUND,
  DOWNLOADING,
}

@Component({
  selector: 'app-appmassive-updater',
  templateUrl: './appmassive-updater.component.html',
  styleUrls: ['appmassive-updater.component.scss'],
})
export class AppmassiveUpdaterComponent implements AfterViewInit {
  @Select((state: GlobalStateModel) => state.app.splashScreen) splashScreen$: Observable<PwaSettings>;
  @Select((state: GlobalStateModel) => state.app.onboardingSlides) onboardingSlides$: Observable<Onboarding[]>;
  @Select((state: GlobalStateModel) => state.app.mainSettings) mainSettings$: Observable<MainSettings>;

  constructor(
    private modalCtrl: ModalController,
    private store: Store,
    private tokenManager: TokenManagerService
  ) {}

  currentState = DownloadState.CHECKING;
  downloadState = DownloadState; // for template use
  capacitor = Capacitor; // for template use

  checkingCopy = 'Checking for fresh goodness…🥖';

  newVersionFoundCopy = 'Deliciousness found…✨';

  private downloadStrings = [
    'We are cooking up new features! 🍳',
    'We are seasoning our app with excellence! 🌶️',
    'We are stirring in fresh enhancements! 🥄',
    'We are rolling out the dough of innovation! 🍕',
    'We are whisking up some app magic! ✨',
    'We are baking fresh updates just for you! 🥖',
    'We are adding some extra cheese to our experience! 🧀',
    'We are mixing a fresh batch of capabilities! 🍲',
    'We are serving up a hot plate of newness! 🍽️',
    'App-etizers are on the way! 🥗',
    'We are tossing up some fresh new features! 🥗',
    'We are blending the best for our users! 🍹',
    'Order up! Fresh new features are coming your way! 📣',
    'We are sizzling up some app enhancements! 🔥',
    'We are whipping up a delightful experience! 🍦',
    'We are refreshing the digital menu! 🍹',
    'We are setting the table with new tools! 🍽️',
    'We are pouring a fresh cup of innovation! ☕',
    'We are dishing out the latest and greatest! 🍲',
  ];

  downloadCopy = this.downloadStrings[Math.floor(Math.random() * this.downloadStrings.length)];

  ngAfterViewInit() {
    setTimeout(async () => {
      await SplashScreen.hide({ fadeOutDuration: 300 });
      await this.startDownloadProcess();
    }, 1000);
  }

  async startDownloadProcess() {
    if (environment.debug) {
      await firstValueFrom(timer(2000));
      return this.finishDownload();
    }
    if (Capacitor.getPlatform() !== 'web') {
      try {
        const shouldUpdate = await this.checkVersion();
        if (shouldUpdate) {
          this.currentState = DownloadState.FOUND;
          try {
            const newestVersion = await CapacitorUpdater.getLatest();
            this.currentState = DownloadState.DOWNLOADING;
            const version: BundleInfo = await CapacitorUpdater.download({
              url: newestVersion.url,
              version: newestVersion.version,
            });
            if (version !== null) {
              try {
                await SplashScreen.show({ autoHide: false });
                await CapacitorUpdater.set(version);
              } catch (error: any) {
                await this.finishDownload(); // in case the set fail, otherwise the new app will have to hide it
                return;
              }
            } else {
              await this.finishDownload();
              return;
            }
          } catch (e) {
            await this.finishDownload();
            return;
          }
        } else {
          await this.finishDownload();
          return;
        }
      } catch (e) {
        await this.finishDownload();
        return;
      }
    } else {
      await this.finishDownload();
      return;
    }
  }

  async finishDownload() {
    await CapacitorUpdater.notifyAppReady();
    if (Capacitor.getPlatform() !== 'web') {
      try {
        const username = await SecureStoragePlugin.get({ key: 'auth.username' });
        const password = await SecureStoragePlugin.get({ key: 'auth.password' });
        if (username && password) {
          await this.store.dispatch(new SignIn(username.value, password.value)).toPromise();
        } else {
          await this.store.dispatch([new InitializeUser(), new SetAdditionalUserFields()]).toPromise();
        }
      } catch (e) {
        try {
          await this.store.dispatch([new InitializeUser(), new SetAdditionalUserFields()]).toPromise();
        } catch (e) {
          await this.tokenManager.clearProviderTokens().toPromise();
          await this.store.dispatch([new InitializeUser(), new SetAdditionalUserFields()]).toPromise();
        }
      }
      await firstValueFrom(timer(500));
    }
    // const versionList = await CapacitorUpdater.list();
    // const latestVersion = await CapacitorUpdater.getLatest();
    // if (versionList.bundles.length > 1) {
    //   versionList.bundles.forEach(async (bundle: BundleInfo) => {
    //     if (bundle.version !== latestVersion.version) {
    //       await CapacitorUpdater.delete({
    //         id: bundle.id,
    //       });
    //     }
    //   });
    // }
    await this.modalCtrl.dismiss();
    this.openOnboarding();
  }

  async checkVersion() {
    const currentVersion = await CapacitorUpdater.current();
    let newestVersion: latestVersion;
    try {
      newestVersion = await CapacitorUpdater.getLatest();
    } catch (e) {
      return false;
    }
    return currentVersion.bundle.version !== newestVersion.version;
  }

  openOnboarding() {
    this.onboardingSlides$
      .pipe(
        filter(onboardingSlides => !!onboardingSlides),
        take(1)
      )
      .subscribe(async onboardingSlides => {
        const data = await Storage.get({ key: 'seenOnboarding' });
        if (!JSON.parse(data.value)) {
          if (Capacitor.getPlatform() !== 'web' && onboardingSlides.length) {
            const onboarding = await this.modalCtrl.create({
              component: OnboardingSlidesComponent,
              keyboardClose: false,
              animated: true,
              mode: 'ios',
              backdropDismiss: false,
            });
            await onboarding.present();
          }
        }
      });
  }
}
